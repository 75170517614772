@import "../../styles/variable.scss";
.headerContainer {
  background: $onPrimaryColor;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 992px) {
    padding-left: 64px;
    padding-right: 64px;
  }
}
.searchDiv {
  padding-left: 25px;
}
.headerTop {
  @media (min-width: 768px) {
    margin-bottom: 12px;
  }
  @media (max-width: 768px) {
    margin-top: 12px;
  }
}

.headerItemTop {
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.64;
}

.headerTopIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.mainHeaderMenu {
  justify-content: flex-end;
}
