@import "../../styles/variable.scss";
.titleContainer {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: $background;
}
.contentContainer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $onPrimaryColor;
}

.inputContainer {
  @media (min-width: 768px) {
    padding-left: 72px;
    padding-right: 72px;
  }
}
.searchImg {
  width: 24px;
  height: 24px;
}
