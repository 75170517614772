@import "../../styles/variable.scss";
.accordionHeader button {
  padding: 24px 32px;
}
.accordionBody {
  padding: 0px;
}
.answerContainer {
  padding: 24px 32px;
  border-bottom: 1px solid $gray2;
}

.actionContainer {
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button {
  width: 132px;
  padding-top: 8px;
  padding-bottom: 8px;
}
