.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.container::-webkit-scrollbar {
  display: none;
}

.iconContainer {
  min-width: 56px;
  min-height: 56px;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.name {
  white-space: nowrap;
}
.arrow {
  min-width: 32px !important;
  min-height: 32px !important;
}
.arrowContainer {
  margin-left: 16px;
  margin-right: 16px;
  min-width: 32px;
  min-height: 32px;
}
.checkedIconContainer {
  position: absolute;
  top: 0px;
  left: 0px;
}
