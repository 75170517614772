@import "../../styles/variable.scss";

.cardImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray2;
  border-radius: 4px;
  --bs-aspect-ratio: calc(136 / 292 * 100%);
}

.cardImage {
  --bs-aspect-ratio: calc(136 / 292 * 100%);
  object-fit: cover;
}

.signatureImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray2;
  border-radius: 4px;
  --bs-aspect-ratio: calc(222 / 616 * 100%);
}

.signatureImage {
  --bs-aspect-ratio: calc(222 / 616 * 100%);
  object-fit: cover;
}
.placeholderImg {
  width: 54px;
  height: 54px;
}
.uploadImg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.helperIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
