@import "../../styles/variable.scss";
.inputContainer {
  position: relative;
}
.rightComponentContainer {
  position: absolute;
  right: 4px;
  bottom: 12px;
  background-color: $onPrimaryColor;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
