$primaryColor: #141ed2;
$lightBlue: #f0f3fa;
$lightBlue2: #e6e7f9;
$onPrimaryColor: #ffffff;
$primaryDarkColor: #00377a;
$onPrimaryDarkColor: #b8c7da;
$secondaryColor: #dd0071;
$secondaryLightColor: #fae6e4;
$textBlack: #111111;
$textBlack2: #112843;
$textBlack2Opacity: rgba(17, 40, 67, 0.5);
$whiteColor: #ffffff;
$gray: #767676;
$grayBackground: #c4c4c4;
$background: #f9f9f9;
$gray2: #eeeeee;
$gray3: #999999;
$errorColor: #cc4149;
$successColor: #5cb157;

$theme-colors: (
  "primary": $primaryColor,
);

$inputHeight: 48px;

// Bootstrap
$navbar-light-active-color: $secondaryColor;
$navbar-light-color: $primaryColor;
$navbar-light-hover-color: $secondaryColor;
$navbar-nav-link-padding-x: 0px;
$navbar-nav-link-padding-x: 0px;
$font-family-base: "Source Sans Pro", "Inter", sans-serif;
$btn-font-family: "SourceSansPro";
$btn-font-weight: bold;
$btn-line-height: 20px;
$btn-font-size: 14px;
$btn-padding-y: 14px;
$btn-padding-x: 16px;
$input-border-color: $gray2;
$accordion-button-active-color: $textBlack;
$accordion-button-bg: $whiteColor;
$accordion-button-active-bg: $whiteColor;
$form-feedback-invalid-color: $errorColor;
$accordion-border-color: $gray2;
