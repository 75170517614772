.dialog {
  @media (min-width: 992px) {
    min-width: 600px !important;
  }
  @media (min-width: 1400) {
    min-width: 634px !important;
  }
}

.dialogLarge {
  @media (min-width: 992px) {
    min-width: 900px !important;
  }
  @media (min-width: 1400) {
    min-width: 920px !important;
  }
}

.behind {
  z-index: 25;
}

.contentContainer {
  padding: 32px;
  @media (max-width: 576px) {
    padding: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  padding: 0px 32px 32px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkButton {
  flex: 1;
  display: flex;
  text-decoration: none;
}
