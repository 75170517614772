.datePickerContainer {
  width: 100%;
  position: relative;
}

.clearImg {
  position: absolute;
  right: 40px;
  top: 11px;
  width: 24px;
  height: 24px;
  z-index: 1;
}
.calendarIcon {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 20px;
  height: 20px;
  z-index: 1;
  pointer-events: none;
}
