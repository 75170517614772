@import "../../styles/variable.scss";
.contentContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  padding: 0px 32px 32px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popupChilren {
  width: 100%;
  padding: 32px 0px;
  @media (max-width: 576px) {
    padding: 20px 0px;
  }
  border-top: 1px solid $gray2;
  border-bottom: 1px solid $gray2;
}

.otpInputContainer {
  box-shadow: 1px 6px 16px rgba(0, 0, 0, 0.08);
  margin-right: 10px;
  border-radius: 4px;
}

.otpInputContainerLast {
  box-shadow: 1px 6px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.otpInputContainerErr {
  border: 0.5px solid $errorColor;
}
.otpInputItem {
  font-family: SourceSansPro;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  max-width: 50px;
  color: $primaryColor;
  border: 0px solid transparent;
}
.otpInputItemErr {
  font-family: SourceSansPro;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  max-width: 50px;
  color: $errorColor;
  border: 0px solid transparent;
}

// .otpInputItemLast {
//   font-family: SVN-Gilroy;
//   font-size: 28px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 42px;
//   letter-spacing: 0em;
//   padding: 16px;
//   background: #ffffff;
//   box-shadow: 1px 6px 16px rgba(0, 0, 0, 0.08);
//   border-radius: 4px;
//   max-width: 50px;
//   color: $primaryColor;
//   border: 0px solid transparent;
// }
.otpInputItem::placeholder {
  color: $primaryColor;
  opacity: 1; /* Firefox */
}
.otpInputItemErr::placeholder {
  color: $errorColor;
  opacity: 1;
}

.otpInputItem:-ms-input-placeholder {
  color: $primaryColor;
}

.otpInputItem::-ms-input-placeholder {
  color: $primaryColor;
}

.otpInputItemLast::placeholder {
  color: $primaryColor;
  opacity: 1; /* Firefox */
}

.otpInputItemLast:-ms-input-placeholder {
  color: $primaryColor;
}

.otpInputItemLast::-ms-input-placeholder {
  color: $primaryColor;
}

.refrehsContainer {
  margin-right: 12px;
}
.remainTime {
  display: inline-block;
  width: 16px;
}
