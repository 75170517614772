.container {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  @media (min-width: 992px) {
    padding-top: 114px;
  }
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
