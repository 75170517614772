@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import "./variable.scss";

@font-face {
  font-family: "SourceSansPro";
  src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SourceSansPro";
  src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "SourceSansPro";
  src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.appH1 {
  font-family: "SourceSansPro";
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;
  }
}

.appH2 {
  font-family: "SourceSansPro";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;
  }
}

.appH3 {
  font-family: "SourceSansPro";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.appH4 {
  font-family: "SourceSansPro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.popupTitle {
  font-family: "SourceSansPro";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.03em;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 24px;
  }
}

.popupTitle2 {
  font-family: "SourceSansPro";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
  }
}
.subTitle {
  font-family: "SourceSansPro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.bodySmall {
  font-family: "SourceSansPro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.title18 {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.title16 {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.smallTitle {
  font-family: "SourceSansPro";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.smallTitle14 {
  font-family: "SourceSansPro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
}

.smallTitle16 {
  font-family: "SourceSansPro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.body14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.body16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
}

.body18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.body20 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.body24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.popupBody {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.body20 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}
.titleAction {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.label {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.caption {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.fontSourceSansPro {
  font-family: SourceSansPro;
}

.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.bold {
  font-weight: bold;
}

.w400 {
  font-weight: 400;
}

.italic {
  font-style: italic !important;
}

.rowStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.rowAllStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.rowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.rowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rowResponsive {
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  justify-content: flex-start;
  align-items: center;
}

.columnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textBlack {
  color: $textBlack;
}

.textBlack2 {
  color: $textBlack2;
}

.textBlack2Opacity {
  color: $textBlack2Opacity;
}
.opacity50 {
  opacity: 0.5;
}
.opacity72 {
  opacity: 0.72;
}
.opacity0 {
  opacity: 0;
}

.textGray {
  color: $gray;
}
.textGray3 {
  color: $gray3;
}

.textPrimary {
  color: $primaryColor;
}
.textSecondary {
  color: $secondaryColor;
}
.textOnPrimary {
  color: $onPrimaryColor;
}
.textSuccess {
  color: $successColor;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.noDecoration {
  text-decoration: none;
}

.bgPrimary {
  background-color: $primaryColor;
}
.bgOnPrimary {
  background-color: $onPrimaryColor;
}

.bgSecondary {
  background-color: $secondaryColor;
}

.bgGray3 {
  background-color: $gray3;
}

.linkNoStyle {
  text-decoration: none;
}

.cardHorizontal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 2px 53px rgba(0, 0, 0, 0.05));
  background-color: $whiteColor;
}
.mt32 {
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}
.mt40 {
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
}
.mb72 {
  margin-bottom: 72px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
}
.mb48 {
  margin-bottom: 48px;
  @media (max-width: 768px) {
    margin-bottom: 28px;
  }
}

.mb40 {
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
}
.mb32 {
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.mb24 {
  margin-bottom: 24px;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
}
.mb18 {
  margin-bottom: 18px;
}
.mb14 {
  margin-bottom: 14px;
}
.pb32 {
  padding-bottom: 32px;
}
.flex1 {
  flex: 1;
}
.fullWidth {
  width: 100%;
}
.prewrap {
  white-space: pre-wrap;
}

.line {
  width: 100%;
  height: 1px;
  background-color: $gray2;
}

.lineLeft {
  height: 1px;
  width: 32px;
  background-color: $textBlack2Opacity;
  margin-right: 16px;
}

.lineLeftOnPrimary {
  height: 1px;
  width: 32px;
  background-color: $onPrimaryColor;
  margin-right: 16px;
}

.required {
  color: red;
}
.invalidText {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $errorColor;
}

.helpText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 0.25rem;
  color: $gray3;
}

.pointer {
  cursor: pointer;
}
/* Loading */
@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Loading large */

.loading div {
  position: absolute;
  width: 64px;
  height: 64px;
  border: 6px solid $primaryColor;
  border-top-color: transparent;
  border-radius: 50%;
}

.loading div {
  animation: loading 1s linear infinite;
  top: 100px;
  left: 100px;
}

.loading-spinner-rolling {
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
  margin: auto;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.loading div {
  box-sizing: content-box;
}

.loading div {
  position: absolute;
  width: 64px;
  height: 64px;
  border: 6px solid $primaryColor;
  border-top-color: transparent;
  border-radius: 50%;
}

.loading div {
  animation: loading 1s linear infinite;
  top: 100px;
  left: 100px;
}

.loading-spinner-rolling {
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
  margin: auto;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.loading div {
  box-sizing: content-box;
}

/* Loading small */
.loadingSmall div {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 3px solid $primaryColor;
  border-top-color: transparent;
  border-radius: 50%;
}

.loadingSmall div {
  animation: loading 1s linear infinite;
  top: 32px;
  left: 32px;
}

.loadingSmall-spinner-rolling {
  width: 64px;
  height: 64px;
  display: block;
  overflow: hidden;
  margin: auto;
}

.loadingSmall {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.loadingSmall div {
  box-sizing: content-box;
}

.mbal__control {
  height: 48px;
  border-color: $gray2 !important;
}
.mbal__indicator.mbal__dropdown-indicator {
  color: $textBlack;
}
.mbal__indicator-separator {
  display: none;
}
