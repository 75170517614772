@import "../../styles/variable.scss";
@import "bootstrap";

.blockExplainLine {
  height: 1px;
  width: 32px;
  background-color: $textBlack2Opacity;
}
.productBlock {
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0.1) 0%,
    rgba(255, 255, 255, 0) 63.02%
  );
  border-radius: 32px;
  padding: 40px 40px 60px 40px;
  @media (max-width: 768px) {
    padding: 16px 16px 24px 16px;
    border-radius: 16x;
  }
  margin-bottom: 18px;
}
.homeInsuranceItem {
  border-radius: 8px;
  width: 100%;
}
.homeInsuranceItemContent {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.homeInsuranceItemImage {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 198px;
  height: 178px;
  @media (max-width: 576px) {
    width: 150px;
    height: 135px;
  }
}

.brandContainer {
  position: relative;
  margin-bottom: 75px;
}
.brandBelow {
  position: absolute;
  width: 100%;
  height: 100px;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: $onPrimaryColor;
}

.homeBranchImgContainer {
  position: relative;
  background-image: url("../../assets/images/home-brand.svg");
  background-size: cover;
  height: 350px;
  @media (max-width: 576px) {
    height: 400px;
  }
  width: 100%;
  border-radius: 16px;
}

.homeBrandContent {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.lineWhiteLeft {
  height: 1px;
  width: 32px;
  background-color: $onPrimaryColor;
  margin-right: 16px;
}
.buttonMoreInfo {
  @include button-variant($secondaryColor, $secondaryColor);
  padding: 16px 60px;
}

.customerSupportBlock {
  padding: 0px 48px 64px 48px;
  @media (max-width: 768px) {
    padding: 0px 0px 24px 0px;
  }
}

.bottomContainer {
  background-color: $lightBlue;
}
.bannerRow {
  @media (min-width: 992px) {
    padding-left: 132px;
  }
  @media (min-width: 1200) {
    padding-left: 150px;
  }
}
.homeBanner {
  width: 100%;
  height: auto;
}
.bannerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
.arrowRight {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}
.discoverButton {
  padding: 16px;
  @media (min-width: 992px) {
    min-width: 250px;
  }
  @media (min-width: 1200px) {
    min-width: 320px;
  }
}
