@import "../../styles/variable.scss";

.couponImgContainer {
  padding: 28px;
  background-color: $primaryColor;
  height: 100%;
  min-height: 139px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.couponImg {
  width: 33.33px;
  height: 21.48px;
}
.voucherLeft {
  width: auto;
  height: 100%;
  min-height: 139px;
}

.voucherRight {
  width: auto;
  height: 100%;
  min-height: 139px;
}

.voucherLeftRightContainer {
  height: 100%;
}
.voucherItemMainContent {
  background-color: $lightBlue;
  padding: 8px;
  display: flex;
  flex-direction: row;
  min-height: 139px;
  flex: 1;
}
.radioImg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.voucherCodeContainer {
  background: linear-gradient(264.84deg, #0097f0 20.83%, #0062c9 92%);
  padding: 4px 8px;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  color: $onPrimaryColor;
  border-radius: 4px;
}

.voucherDetailContainer {
  cursor: pointer;
}
