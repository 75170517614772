@import "./variable.scss";
.pageContainer {
  padding: 56px 16px;
  background-color: $background;
}
.infoForm {
  border: 1px solid $background;
  box-sizing: border-box;
  box-shadow: 0px 2px 52px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 32px;
  background-color: $whiteColor;
  margin-bottom: 16px;
  @media (max-width: 576px) {
    padding: 20px;
  }
}
.stepper {
  margin-bottom: 64px;
}
.popupChilren {
  width: 100%;
  padding: 32px 0px;
  border-top: 1px solid $gray2;
  border-bottom: 1px solid $gray2;
  @media (max-width: 576px) {
    padding: 20px 0px;
  }
}
.checkedIconContainer {
  margin-right: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.congratulationImage {
  margin-bottom: 22px;
}
.congratulationContent {
  text-align: center;
  padding: 0px 32px;
}
.commitmentContent {
  background-color: $background;
  height: 400px;
  padding: 16px;
  overflow-y: scroll;
}
.cardImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray2;
  border-radius: 4px;
  --bs-aspect-ratio: calc(136 / 292 * 100%);
}

.cardImage {
  --bs-aspect-ratio: calc(136 / 292 * 100%);
  object-fit: cover;
}

.signatureImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray2;
  border-radius: 4px;
  --bs-aspect-ratio: calc(222 / 616 * 100%);
}

.signatureImage {
  --bs-aspect-ratio: calc(222 / 616 * 100%);
  object-fit: cover;
}
.placeholderImg {
  width: 54px;
  height: 54px;
}
.uploadImg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.linkActive {
  color: $primaryColor;
  text-decoration: none;
}

.linkInactive {
  color: $primaryColor;
  opacity: 0.5;
  text-decoration: none;
}
.helperIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.payWithQrBlock {
  padding: 16px;
  border: 0.5px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $background;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.radioItemActive {
  padding: 16px;
  border: 0.5px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $background;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.radioItemInactive {
  padding: 16px;
  border: 0.5px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $background;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}
.radioImg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
}
.bankLogo {
  width: auto;
  height: 24px;
}
