@import "../../styles/variable.scss";
.homeInsuranceItem {
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 2px 53px rgba(0, 0, 0, 0.05));
  background-color: $whiteColor;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
}
.homeInsuranceItemContent {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.homeInsuranceItemImage {
  border-radius: 8px;
  @media (min-width: 1200px) {
    width: 198px;
    height: 178px;
  }

  @media (max-width: 1200px) {
    border-radius: 8px;
    width: 100%;
    height: auto;
  }
}
