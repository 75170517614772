@import "../../styles/variable.scss";

.blockExplainLine {
  height: 1px;
  width: 32px;
  background-color: $textBlack2Opacity;
}
.item {
  background-color: $onPrimaryColor;
  padding: 32px;
  @media (max-width: 768px) {
    padding: 20px;
  }
  border-radius: 16px;
  height: 100%;
}
.forwardImg {
  width: 21px;
  height: 21px;
  margin-left: 8px;
}
.aboutImg {
  width: 100%;
  height: auto;
}
