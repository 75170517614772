@import "../../styles/variable.scss";
.itemActive {
  padding: 16px;
  border: 0.5px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $background;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.itemInactive {
  padding: 16px;
  border: 0.5px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $background;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}
.radioImg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
}
