@import "../../styles/variable.scss";
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.leftItem {
  margin-right: 20px;
}

.errorItem {
  flex: 1;
  padding: 12px;
  background-color: $whiteColor;
  border: 0.5px solid $errorColor;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inactiveItem {
  flex: 1;
  padding: 12px;
  background-color: $whiteColor;
  border: 0.5px solid $gray2;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.activeItem {
  flex: 1;
  padding: 12px;
  background-color: $primaryColor;
  border: 0.5px solid $gray2;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
