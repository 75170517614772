@import "../../styles/variable.scss";
.banner {
  width: 100%;
  height: auto;
}
.promotionNameBlock {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: $primaryColor;
  @media (max-width: 576px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.ruleBlock {
  background-color: $background;
  padding-top: 72px;
  padding-bottom: 48px;
  @media (max-width: 576px) {
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

.icon {
  width: 48px;
  height: 48px;
  margin-bottom: 14px;
}
.infoItem {
  background-color: $onPrimaryColor;
  padding: 32px;
  border-radius: 8px;
  @media (max-width: 576px) {
    padding: 20px;
  }
}

.infoItemTop {
  background-color: $onPrimaryColor;
  padding: 32px;
  border-radius: 8px;
  margin-bottom: 16px;
  @media (max-width: 576px) {
    padding: 20px;
  }
}

.programDetailBlock {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: $onPrimaryColor;
  @media (max-width: 576px) {
    padding: 32px 16px 32px 16px;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0.1) 0%,
      rgba(255, 255, 255, 0) 63.02%
    );
  }
}
.programDetailContainer {
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0.1) 0%,
    rgba(255, 255, 255, 0) 63.02%
  );
  border-radius: 32px;
  padding: 32px;
  @media (max-width: 768px) {
    padding: 16px;
    border-radius: 0px;
  }
}

.programDetailRow {
  padding: 32px;
  background-color: $background;
  @media (max-width: 576px) {
    padding: 20px;
    margin-bottom: 16px;
  }
}
.promotionImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promotionRewardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 32px;
  background-color: $background;
  @media (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.checkImg {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.whiteSpacer40 {
  background-color: $onPrimaryColor;
  height: 40px;
}
