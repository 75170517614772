@import "../../styles/variable.scss";
.voucherCodeContainer {
  position: relative;
}
.voucherCodeValid {
  color: $secondaryColor;
  background-color: $secondaryLightColor;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  top: 40px;
  left: 8px;
  z-index: 20;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.rightImg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 42px;
  right: 16px;
  z-index: 20;
}
.forwardCircle {
  width: 21px;
  height: 21px;
  margin-left: 9.5px;
}
.chooseVoucherContainer {
  cursor: pointer;
}
