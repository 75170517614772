@import "../../styles/variable.scss";
.clearImg {
  width: 24px;
  height: 24px;
}
.content {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $gray2;
}

.sorting,
.sortingAsc,
.sortingDesc {
  padding: 8px 21px 4px 4px;
  cursor: pointer;
}
.sorting {
  background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==)
    no-repeat center right !important;
}
.sortingAsc {
  background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIRnC2nKLnT4or00Puy3rx7VQAAOw==)
    no-repeat center right !important;
  // border-bottom: #70aadd 2px solid;
}
.sortingDesc {
  background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=)
    no-repeat center right !important;
  // border-bottom: 2px solid #b00;
}
.searchButtonContainer {
  margin-top: 30px;
}
