@import "../../styles/variable.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.container::-webkit-scrollbar {
  display: none;
}

.itemInactive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 32px;
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $onPrimaryColor;
  white-space: nowrap;
}

.itemActive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 32px;
  border: 1px solid $secondaryColor;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $secondaryColor;
  white-space: nowrap;
}
.itemMargin {
  margin-right: 40px;
}
.icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
