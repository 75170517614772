@import "../../styles/variable.scss";
.container {
  background-color: $whiteColor;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 2px 53px rgba(0, 0, 0, 0.05));
  border-radius: 8px;
}
.imageContainer {
  position: relative;
}
.image {
  border-radius: 8px 8px 0px 0px;
  height: auto;
  width: 100%;
  background-size: cover;
}

.content {
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.benefitRow {
  display: flex;
  margin-bottom: 12px;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
}

.benefitRow2 {
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
}
.discountContainer {
  position: absolute;
  top: 12px;
  left: 12px;
  background: $whiteColor;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
}

.discountContainerIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $secondaryColor;
  border-radius: 8px 0px 0px 8px;
  padding: 8px;
}
.discountContainerContent {
  padding: 9.5px 8px;
}
.voucherBlock {
  background-color: $lightBlue;
  padding: 16px;
  margin-top: 16px;
}
