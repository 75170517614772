@import "../../styles/variable.scss";
.container {
  margin-top: 32px;
}
.producerSortInfoRow {
  position: relative;
  margin-bottom: 72px;
}
.productSortInfoOuter {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.productSortInfoContainer {
  background: $onPrimaryColor;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 32px;
  @media (max-width: 576px) {
    padding: 16px;
  }
}

.cancerImg1 {
  width: 100%;
  height: auto;
}

.canderImg2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.descriptionRow {
  margin-bottom: 72px;
}
.descriptionContainer {
  background-color: $background;
  padding: 32px;
  height: 100%;
  @media (max-width: 576px) {
    padding: 20px;
  }
}

.description {
  font-size: 18px;
  line-height: 24px;
  color: $textBlack;
  opacity: 0.72;
}

.aboutProductRow {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: $lightBlue;
  @media (max-width: 576px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.aboutProductItem {
  background-color: $onPrimaryColor;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 32px;
  @media (max-width: 576px) {
    padding: 20px;
    margin-bottom: 16px;
  }
}

.aboutProductInfoBlock {
  padding: 32px;
  background-color: $onPrimaryColor;
  border-radius: 8px;
  @media (max-width: 576px) {
    padding: 20px;
  }
}
.arrowRight {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}
.buyButtonLg {
  padding: 16px 60px;
}
.aboutProductBuyContainer {
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}
