@import "../../styles/variable.scss";
.footer {
  background-color: $primaryDarkColor;
  width: 100%;
  padding: 24px 24px 24px 24px;
  display: flex;
  align-items: center;
}

.footerCopyright {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $onPrimaryDarkColor;
  display: flex;
  align-items: center;
  justify-content: center;

  // margin-right: 380px;
}

.faqContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerMBAL {
  font-weight: bold;
  color: $onPrimaryColor;
}
.footerLink {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 22px;
  color: $onPrimaryColor;
  text-decoration: none;
}
