@import "../../styles/variable.scss";
.container {
  margin-top: 32px;
}
.producerSortInfoRow {
  position: relative;
  margin-bottom: 72px;
}
.productSortInfoOuter {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.productSortInfoContainer {
  background: $onPrimaryColor;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 32px;
  @media (min-width: 992px) {
    max-width: 500px;
  }
  @media (max-width: 576px) {
    padding: 16px 16px 64px 16px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.descriptionContainer {
  background-color: $lightBlue2;
  padding: 32px;
  height: 100%;
  @media (max-width: 576px) {
    padding: 20px;
  }
}

.description {
  font-size: 18px;
  line-height: 24px;
  color: $textBlack;
  opacity: 0.72;
}

.aboutProductRow {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: $lightBlue;
  @media (max-width: 576px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.aboutProductItem {
  background-color: $onPrimaryColor;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 40px;
  @media (max-width: 576px) {
    margin-bottom: 16px;
    padding: 20px;
  }
}
.aboutProductItemLast {
  background-color: $onPrimaryColor;
  border-radius: 8px;
  padding: 24px;
  @media (max-width: 576px) {
    padding: 20px;
  }
}
.aboutProductIconContainer {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: $lightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}
.aboutProductIcon {
  width: 20px;
  height: 20px;
}

.aboutProductInfoBlock {
  padding: 32px;
  background-color: $onPrimaryColor;
  border-radius: 8px;
  padding: 20px;
}
.arrowRight {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.productBenefitContainer {
  border-radius: 8px;
  background-color: $background;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.producerBenefitRow {
  position: relative;
}

.productBenefitOuter {
  position: absolute;
  top: 0px;
  left: 120px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
}
